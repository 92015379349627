export const environment = {
  production: false,
  environmentName: 'test',
  api: 'https://barometer-test.syensqo.com',
  firebase: {
    apiKey: 'AIzaSyBRclwN7bubb6ITsEYlt1P_TUuSsoS6zDw',
    authDomain: 'gcp-sqo-barometer-t.firebaseapp.com',
    projectId: 'gcp-sqo-barometer-t',
    storageBucket: 'gcp-sqo-barometer-t.appspot.com',
    messagingSenderId: '127992349043',
    appId: '1:127992349043:web:d21ebafcee80a590804506',
  },
  gtagTrackingId: 'UA-115813374-10',
};
